import React from "react"
import RootLayout from "../layouts/RootLayout";


export default function NotFound() {
  return (
    <RootLayout>
      <div style={{textAlign: 'center'}}>
        <h1>Page Not Found</h1>
        <p>Oops, we couldn't find this page!</p>
      </div>
    </RootLayout>
  )
}
